import { trackEvent } from '../../services/event-tracker'

export function trackWizardStepCompleted(
  extraData: Record<string, string | boolean | string[]>
) {
  trackEvent('$track_wizard_next_step_clicked', {
    ...extraData,
  })
}

export function trackWizardRequirementChange(
  extraData: Record<string, string | number | undefined>
) {
  trackEvent('$track_wizard_requirement_level_changed', {
    ...extraData,
  })
}

export function trackWizardEvent(
  eventName: string,
  extraData: Record<string, string | undefined | number>
) {
  trackEvent(eventName, { ...extraData })
}
