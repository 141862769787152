import * as React from 'react'
import { Toaster } from 'react-hot-toast'
import { Wizard as BaseWizard } from 'react-use-wizard'
import { WizardContextProvider } from './wizard-context'

export type RootProps = {
  children: React.ReactNode
}

export const Root: React.VFC<RootProps> = (props) => {
  const { children } = props

  return (
    <WizardContextProvider>
      <Toaster
        toastOptions={{
          style: {
            maxWidth: '500px',
            borderRadius: '0.25rem',
          },
        }}
      />
      <BaseWizard>{children}</BaseWizard>
    </WizardContextProvider>
  )
}
