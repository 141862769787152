import { useWizardContext } from 'components/wizard/wizard-context'
import React from 'react'
import * as Wizard from 'components/wizard'
import * as AppFramePreview from 'components/onboarding-build-wizard/app-frame-preview'

import { FrameworkPage, PositionCard, RootProvider } from 'src/design-system'
import { WizardFrameworkVm } from './wizard-framework-vm'
import { WizardPositionsVm } from './wizard-positions-vm'
import { SummaryPositionCoachMark } from './summary-position-coach-mark'

export const SummaryStep = () => {
  const {
    orgName,
    teamName,
    role,
    orgSize,
    teamDiscipline,
    frameworkId,
    initialPositionId,
    selectedPositions,
    selectedSkills,
    setSelectedSkills,
    selectedRequirementLevels,
    setSelectedRequirementLevels,
    activeTab,
    setTabOptions,
    setActiveTab,
    aiSkillName,
    aiSkillGenerationTaskId,
  } = useWizardContext()

  const frameworkVm = new WizardFrameworkVm(
    orgName,
    teamName,
    role,
    orgSize,
    frameworkId,
    initialPositionId,
    teamDiscipline,
    selectedPositions,
    selectedSkills,
    setSelectedSkills,
    selectedRequirementLevels,
    setSelectedRequirementLevels,
    aiSkillName,
    aiSkillGenerationTaskId
  )

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const positionsVm = React.useMemo(() => {
    return new WizardPositionsVm(frameworkId, initialPositionId, selectedSkills)
  }, [frameworkId, initialPositionId, selectedSkills])

  React.useEffect(() => {
    if (!activeTab) {
      setTabOptions([
        { value: 'positions', label: 'Positions' },
        { value: 'framework', label: 'Framework' },
      ])
      setActiveTab({ value: 'positions', label: 'Positions' })
    }
  }, [activeTab, setActiveTab, setTabOptions])

  return (
    <Wizard.Step>
      <Wizard.FullPage>
        <AppFramePreview.Wrapper
          wrapperClass="w-min md:min-w-[750px] lg:min-w-[1230px]"
          contentClassName="w-min"
          teamName={teamName}
          enableTabs
          enableInvite
        >
          <AppFramePreview.Tab name="positions">
            <div className="h-3 mb-4 ml-1 mt-8 font-bold text-base text-gray-600">
              Individual Contributors
            </div>
            <div className="flex flex-row items-start gap-x-2">
              <SummaryPositionCoachMark />
              {selectedPositions &&
                selectedPositions.map((positionId, index) => (
                  <PositionCard
                    key={index}
                    className="pointer-events-none"
                    parentDiscipline={{ id: 1, name: teamDiscipline }}
                    position={{
                      seniorityLevel:
                        positionsVm.seniorityLevelForPosition(positionId),
                      name: positionsVm.nameForPosition(positionId),
                      customLabel: `${
                        teamDiscipline[0]
                      }${positionsVm.seniorityLevelForPosition(positionId)}`,
                      activeUsers: positionsVm.activeUsersForPosition(
                        positionId
                      ) as any,
                      requirements: positionsVm.requirementsForPosition,
                    }}
                    permissions={{ showLabels: true }}
                  />
                ))}
            </div>
          </AppFramePreview.Tab>
          <AppFramePreview.Tab name="framework">
            <FrameworkPage
              disciplines={[frameworkVm.frameworkDiscipline as any]}
              categories={frameworkVm.categorisedFrameworkSkills as any}
              requirements={selectedRequirementLevels as any}
              uncategorisedSkills={[]}
              permissions={{
                allowChangeRequirement: false,
              }}
            />
          </AppFramePreview.Tab>
        </AppFramePreview.Wrapper>
      </Wizard.FullPage>
    </Wizard.Step>
  )
}
