import * as React from 'react'
import cn from 'classnames'
import { useWizardContext } from 'components/wizard/wizard-context'

export type TabProps = {
  name: string
  children?: React.ReactNode
}

export const Tab: React.VFC<TabProps> = (props: TabProps) => {
  const { name, children } = props

  const { activeTab } = useWizardContext()

  if (activeTab?.value !== name) {
    return null
  }

  return <div className={cn('p-8')}>{children}</div>
}
