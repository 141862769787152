import { ArrowElbowDownLeft, Sparkle, X } from '@phosphor-icons/react'
import cn from 'classnames'
import React, { KeyboardEventHandler } from 'react'
import { AiSkillGeneratorVm } from './ai-skill-generator-vm'
import { errorToast } from '../../utils/error-toast'
import { Input } from 'components/atoms/input'
import { LoadingContent } from 'components/loading-content'
import { observer } from 'mobx-react-lite'
import { successToast } from '../../utils/success-toast'
import { useStore } from 'store/context'

export type AiSkillGeneratorProps = {
  aiSkillName: string
  setAiSkillName: (aiSkillName: string) => void
  aiSkillGenerationTaskId: string
  setAiSkillGenerationTaskId: (taskId: string) => void
  onGenerationComplete: (id: string) => void
}

export const AiSkillGenerator = observer((props: AiSkillGeneratorProps) => {
  const {
    aiSkillGenerationTaskId,
    setAiSkillGenerationTaskId,
    aiSkillName,
    setAiSkillName,
    onGenerationComplete,
  } = props
  const { asyncTasks } = useStore()

  const [aiClicked, setAiClicked] = React.useState(false)

  const task = asyncTasks.byId(aiSkillGenerationTaskId)

  const vm = new AiSkillGeneratorVm(aiSkillName, aiSkillGenerationTaskId)

  const handleStart = async () => {
    if (aiSkillGenerationTaskId || aiSkillName === '') return

    setAiClicked(false)

    const taskId = await vm.beginAIGeneration()

    if (!taskId) {
      errorToast('Sorry there was a problem, please try again')
      return
    } else {
      setAiSkillGenerationTaskId(taskId)

      const poll = await vm.pollForTaskComplete(taskId)
      if (poll?.isSuccess) {
        onGenerationComplete(taskId)

        successToast('Skill generated!')
      } else {
        errorToast('Sorry there was a problem, please try again')
      }
    }
  }

  const handleKeyDown: KeyboardEventHandler = (event) => {
    switch (event.key) {
      case 'Enter':
        handleStart()
        event.preventDefault()
    }
  }

  return (
    <div
      key="ai-button"
      className={cn(
        'flex items-center relative px-4 py-2 border-px bg-pink-100 text-pink-700 border-gray-100 border-solid rounded cursor-pointer min-h-[64px] w-full'
      )}
      onClick={() => setAiClicked(!aiClicked)}
    >
      {!task?.isFinished && (
        <LoadingContent
          className="!text-pink-600 scale-75"
          loading={!!task?.isPending}
          variant="highlight"
        >
          <Sparkle className="text-pink-600" weight="fill" />
        </LoadingContent>
      )}

      {task?.isFinished && (
        <div className={cn('w-max pr-8 cursor-pointer flex-grow')}>
          <p className="font-bold m-0 text-pink-700">{aiSkillName}</p>
        </div>
      )}
      {(!task?.isFinished || !vm.generationInProgress) && (
        <>
          <Input
            className="text-pink-700 placeholder-pink-700 border-0 min-w-min"
            inputClassName="!border-0 !shadow-none !outline-none text-pink-700"
            nonFocusedPlaceholder="Add your own skill with our AI generator"
            placeholder="Skill name"
            naked={true}
            value={aiSkillName}
            forceShowPlaceholder={!aiSkillName}
            disabled={vm.generationInProgress}
            onKeyDown={(e) => handleKeyDown(e)}
            onChange={(e) => {
              setAiSkillName(e || '')
            }}
            onBlur={handleStart}
          />
          <ArrowElbowDownLeft className="h-4 w-4" weight="bold" />
        </>
      )}

      {task?.isFinished && (
        <X
          weight="bold"
          className=""
          onClick={() => {
            setAiSkillName('')
            setAiSkillGenerationTaskId('')
            setAiClicked(false)
          }}
        />
      )}
    </div>
  )
})
