import { Handshake, Icon, UserCircle, Users } from '@phosphor-icons/react'
import React from 'react'
import cn from 'classnames'
import { useWizardContext } from 'components/wizard/wizard-context'

const roles = {
  manager: 'Manager',
  hr: 'HR, People Ops or Talent team',
  other: 'Other',
}

export const RoleOptions = () => {
  const { role, setRole } = useWizardContext()

  const onClick = (value: string) => {
    setRole(value)
  }

  return (
    <div className="gap-2 grid grid-cols-1 2xl:grid-cols-3">
      <OnboardingOption
        value={roles['manager']}
        selected={role}
        onClick={onClick}
        icon={Users}
      >
        <p className="mb-0 text-gray-600">with direct reports</p>
      </OnboardingOption>

      <OnboardingOption
        value={roles['hr']}
        selected={role}
        onClick={onClick}
        icon={Handshake}
      />

      <OnboardingOption
        value={roles['other']}
        selected={role}
        onClick={onClick}
        icon={UserCircle}
      />
    </div>
  )
}

type OnboardingOptionProps = {
  value: string
  selected: string | null
  onClick?: (value: string) => void
  icon: Icon
  children?: React.ReactNode
}

const OnboardingOption = (props: OnboardingOptionProps) => {
  const { value, selected, onClick, icon, children } = props

  const Icon = icon

  const isSelected = selected === value

  return (
    <button
      type="button"
      className={cn(
        'flex flex-col hover:bg-gray-50 px-3 py-4 rounded text-left border-2 border-solid',
        isSelected ? 'bg-gray-50 border-green-600' : 'border-gray-100'
      )}
      onClick={() => onClick?.(value)}
    >
      <Icon
        className={cn(
          'h-6 mb-3 w-6',
          isSelected ? 'text-green-600' : 'text-gray-600'
        )}
        weight="bold"
      />
      <div className="font-semibold text-base">{value}</div>
      {children}
    </button>
  )
}
