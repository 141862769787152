import { Banner } from 'src/design-system'
import { LightbulbFilament } from '@phosphor-icons/react'
import * as React from 'react'

export const BuildWizardBanner = ({
  children,
}: {
  children: React.ReactNode
}) => (
  <Banner variant="success" className="rounded justify-start mt-2 gap-x-3">
    <LightbulbFilament weight="bold" className="w-4 h-4 flex-shrink-0" />
    {children}
  </Banner>
)
