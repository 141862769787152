import {
  SelectedSkill,
  SkillRequirement,
} from 'components/wizard/wizard-context'
import { store } from 'store/index'
import { FrameworksSkill } from 'store/modules/frameworks-skills'
import { trackWizardRequirementChange } from '../wizard-tracking-helper'
import { AsyncTask } from 'store/modules/async-tasks'
// eslint-disable-next-line import/extensions
import { OnChangeRequirementLevelProps } from 'app/packs/src/design-system/hooks/use-framework/use-framework.types'

type DummyOnboardingCategory = {
  id: string
  name: string
  listPosition: number
  skills: DummyOnboardingSkill[]
}

type DummyOnboardingSkill = {
  id: string
  categoryId: string
  description?: string | null
  name: string
  slug: string
  sortIndex: number
  skillLevels?: DummyOnboardingSkillLevel[]
}

type DummyOnboardingSkillLevel = {
  id: string
  level: number
  name: string
  skillId: string
}

export class WizardFrameworkVm {
  constructor(
    private orgName: string,
    private teamName: string,
    private role: string,
    private orgSize: string,
    private frameworkId: string,
    private initialPositionId: string,
    private teamDiscipline: string,
    private selectedPositions: string[],
    private selectedSkills: SelectedSkill[],
    private setSelectedSkills: (skills: SelectedSkill[]) => void,
    private selectedRequirementLevels: SkillRequirement[],
    private setSelectedRequirementLevels: (
      requirementLevels: SkillRequirement[]
    ) => void,
    private aiSkillName: string,
    private aiSkillGenerationTaskId: string
  ) {}

  async onContinue() {
    try {
      const response = await fetch('/orgs/build_wizard_setup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.body),
      })
      const json = await response.json()
      return { success: true, ...json }
    } catch (e) {
      console.error(e)
      return { success: false, message: (e as Error).message }
    }
  }

  get body() {
    return {
      org_name: this.orgName,
      team_name: this.teamName,
      role: this.role,
      org_size: this.orgSize,
      discipline: this.teamDiscipline,
      framework_id: this.frameworkId,
      initial_position_id: this.initialPositionId,
      selected_positions: this.selectedPositions,
      ai_skill_generation_task_id: this.aiSkillGenerationTaskId,
      selected_skills: this.selectedSkills.map((skill) => {
        return { skill_id: skill.skillId, category_id: skill.categoryId }
      }),
      selected_requirement_levels: this.selectedRequirementLevels.map((r) => {
        return {
          skill_id: r.skillId,
          position_id: r.positionId,
          level: r.level,
        }
      }),
    }
  }

  onSkillClicked(
    alreadySelected: string | undefined,
    { skill, category }: FrameworksSkill
  ) {
    if (!skill || !category) return
    if (alreadySelected) {
      // update the skill
      this.setSelectedSkills(
        this.selectedSkills.filter(
          (selectedSkill) => selectedSkill.skillId !== skill.id
        )
      )
      // remove requirements
      this.setSelectedRequirementLevels(
        this.selectedRequirementLevels.filter(
          (requirement) => requirement.skillId !== skill.id
        )
      )
    } else {
      // add the selected skill to the list of selected skills
      this.setSelectedSkills([
        ...this.selectedSkills,
        {
          skillId: skill.id,
          categoryId: category.id,
        },
      ])

      // find & update the requirement in our state
      const requirements = this.positions.map((position, index) => {
        return {
          id: Math.random(),
          level: index + 1,
          skillId: skill.id,
          positionId: `${position.id}`,
        }
      })
      this.setSelectedRequirementLevels([
        ...this.selectedRequirementLevels,
        ...requirements,
      ])
    }
  }

  onAiSkillReady(id: string) {
    // once the skill is ready, generate some placeholder requirements
    const requirements = this.positions.map((position, index) => {
      return {
        id: Math.random(),
        level: index + 1,
        skillId: id,
        positionId: `${position.id}`,
      }
    })
    this.setSelectedRequirementLevels([
      ...this.selectedRequirementLevels,
      ...requirements,
    ])
  }

  handleOnChangeRequirementLevel(clickProps: OnChangeRequirementLevelProps) {
    trackWizardRequirementChange({
      skill: this.frameworkSkills.find(
        (skill) => `${clickProps.skillId}` === skill.id
      )?.name,
      position: this.positions.find(
        (position) => `${clickProps.positionId}` === position.id
      )?.name,
      new_level: clickProps.newLevel,
    })

    const newRequirementLevels = this.selectedRequirementLevels.map(
      (requirement) => {
        if (
          requirement.skillId === `${clickProps.skillId}` &&
          requirement.positionId === `${clickProps.positionId}`
        ) {
          return {
            ...requirement,
            level: clickProps.newLevel,
          }
        }
        return requirement
      }
    )

    this.setSelectedRequirementLevels(newRequirementLevels)
  }

  get frameworkSkills(): DummyOnboardingSkill[] {
    const selectedSkills = this.selectedSkills.map((skill) => {
      const currentSkill = store.skills.byId(skill.skillId)
      const skillLevels = currentSkill?.defaultVariant?.skillLevels || []

      const currentSkillLevels: DummyOnboardingSkillLevel[] = skillLevels.map(
        (skillLevel) => {
          return {
            id: skillLevel.id,
            level: skillLevel.level,
            name: skillLevel.name || '',
            skillId: skill.skillId,
          }
        }
      )

      return {
        id: skill.skillId,
        categoryId: skill.categoryId,
        description: currentSkill?.description,
        name: currentSkill?.name || '',
        slug: currentSkill?.slug || '',
        sortIndex: 1,
        skillLevels: currentSkillLevels,
      }
    })

    if (this.aiTask?.isFinished && this.aiTask?.isSuccess) {
      const aiSkill = this.buildSkillFromAiGeneration

      return [...selectedSkills, aiSkill]
    }

    return selectedSkills
  }

  get aiTask(): AsyncTask | undefined {
    return store.asyncTasks.byId(this.aiSkillGenerationTaskId)
  }

  get buildSkillFromAiGeneration(): DummyOnboardingSkill {
    return {
      id: this.aiSkillGenerationTaskId,
      categoryId: '1',
      description: this.aiTask?.output.skillDescription?.output.extracted,
      name: this.aiSkillName,
      slug: '',
      sortIndex: 1,
      skillLevels: this.buildSkillLevelsFromAiGeneration,
    }
  }

  get buildSkillLevelsFromAiGeneration(): DummyOnboardingSkillLevel[] {
    const skillLevelArray =
      this.aiTask?.output.skillLevelDescriptions?.output.extracted

    if (!skillLevelArray) {
      return []
    }

    const levels = skillLevelArray.map((level) => {
      return {
        id: `${Math.random()}`,
        level: parseInt(level.level),
        name: level.name,
        skillId: this.aiSkillGenerationTaskId,
      }
    })

    return levels
  }

  get categorisedFrameworkSkills() {
    return this.frameworkSkills.reduce<DummyOnboardingCategory[]>(
      (acc, skill) => {
        const category = acc.find((cat) => cat.id === skill.categoryId)
        if (category) {
          category.skills.push(skill)
        } else {
          const categoryName =
            store.categories.byId(`${skill.categoryId}`)?.name || ''
          acc.push({
            id: skill.categoryId,
            name: categoryName,
            listPosition: 1,
            skills: [skill],
          })
        }
        return acc
      },
      []
    )
  }

  get positions() {
    return this.selectedPositions.map((positionId) => {
      const position = store.positions.byId(positionId)
      return {
        id: position?.id || 1,
        name: position?.name || '',
        seniorityLevel: position?.seniorityLevel || '',
        customLabel: `${this.teamDiscipline[0]}${
          position?.seniorityLevel || 'E'
        }`,
      }
    })
  }

  get frameworkDiscipline() {
    return {
      name: 'Individual Contributors',
      id: 2,
      positions: this.positions,
    }
  }

  get canContinue() {
    const limit = this.aiSkillGenerationTaskId ? 2 : 3

    return this.selectedSkills.length >= limit
  }

  /**
   * Builds list of skills that user can select in this part of the wizard.
   */
  get selectableSkills() {
    return [
      ...this.humanSkills,
      ...this.leadershipSkills,
      ...this.craftSkillsForPosition,
    ]
  }

  get skillsInFramework(): FrameworksSkill[] {
    return store.frameworks.byId(this.frameworkId)?.frameworksSkills || []
  }

  get humanSkills() {
    return this.skillsInFramework
      .filter((frameworkSkill) => {
        return frameworkSkill.category?.name === 'Human'
      })
      .slice(0, 3)
  }
  get leadershipSkills() {
    return this.skillsInFramework
      .filter(
        (frameworkSkill) => frameworkSkill.category?.name === 'Leadership'
      )
      .slice(0, 3)
  }

  get craftSkillsForPosition() {
    const craftSkills = this.skillsInFramework.filter(
      (frameworkSkill) => frameworkSkill.category?.name === 'Craft'
    )
    return craftSkills
      .filter((craftSkill) => {
        const position = store.positions.byId(this.initialPositionId)
        const positionSkills = position?.skills || []
        return positionSkills.some((positionSkill) => {
          return positionSkill && positionSkill.id === craftSkill.skill?.id
        })
      })
      .slice(0, 3)
  }
}
