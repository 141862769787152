import * as React from 'react'
import { Button, Card, Input } from 'src/design-system'
import { Toaster } from 'react-hot-toast'
import { SelectInput } from '../atoms/select-input'
import { WithAnalytics } from '../with-analytics'
import { OnboardingFrameworkSetupHelperVm } from './onboarding-framework-setup-helper-vm'

type OnboardingFrameworkSetupHelperProps = {
  teamId: string | null
  teamName: string
  frameworkName: string
  image: string
  avatar: string
}

export const OnboardingFrameworkSetupHelper: React.VFC<
  OnboardingFrameworkSetupHelperProps
> = (props) => {
  const { teamId, teamName, frameworkName, avatar, image } = props

  const [showTemplateConfirmation, setShowTemplateConfirmation] =
    React.useState<boolean>(teamId !== null)

  const vm = new OnboardingFrameworkSetupHelperVm(
    teamId,
    setShowTemplateConfirmation
  )

  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            maxWidth: '500px',
            borderRadius: '0.25rem',
          },
        }}
      />
      {showTemplateConfirmation ? (
        <>
          <div className="mb-2">You&apos;ve chosen</div>
          <Card className="m-0 mb-8">
            <img
              src={image}
              className="object-cover h-52 w-full mb-6 rounded-md"
            />

            <h4 className="mb-2 text-xl">{frameworkName}</h4>
            <p className="flex items-center text-sm">
              By{' '}
              <span
                className="inline mx-1"
                dangerouslySetInnerHTML={{
                  __html: avatar,
                }}
              ></span>
              {teamName}
            </p>
          </Card>
          <div className="flex gap-x-2">
            <WithAnalytics
              event="$track_onboarding_questionnaire_continue_clicked"
              params={{ step: 'three' }}
            >
              <Button variant="default" type="submit">
                Continue with this template
              </Button>
            </WithAnalytics>
            <Button
              variant="outline"
              type="button"
              onClick={() => vm.onResetTemplate()}
            >
              Start from scratch
            </Button>
          </div>
        </>
      ) : (
        <>
          <h3 className="mb-2 text-base text-gray-800">
            What&apos;s your track?
          </h3>
          <div className="form-group m-0 onboarding-questionnaire-tools relative">
            <div className="col-md-6 p-0">
              <SelectInput
                name="org[onboarding_survey_attributes][whats_your_discipline]"
                placeholder="Select a track"
                options={vm.disciplineOptions}
              />
            </div>
            <h3 className="mt-10 mb-2 text-base text-gray-800">
              How many people are there in the team?
            </h3>
            <div className="form-group m-0 relative">
              <Input
                name="org[onboarding_survey_attributes][how_many_people_are_there_in_the_team]"
                type="number"
                min={1}
                step={1}
                className="col-md-2"
              />
            </div>
            <div className="bottom mt-10">
              <WithAnalytics
                event="$track_onboarding_questionnaire_continue_clicked"
                params={{ step: 'three' }}
              >
                <Button variant="default">Finish & start exploring</Button>
              </WithAnalytics>
            </div>
          </div>
        </>
      )}
    </>
  )
}
