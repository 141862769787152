import * as React from 'react'
import cn from 'classnames'

export type StepProps = {
  children: React.ReactNode
  className?: string
}

export const Step: React.VFC<StepProps> = (props) => {
  const { children, className } = props

  return (
    <div className={cn('flex flex-row h-full w-full', className)}>
      {children}
    </div>
  )
}
