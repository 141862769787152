import { Option } from 'components/atoms/select-input'
import { SelectedSkill } from 'components/wizard/wizard-context'
import { store } from 'store/index'
import { User } from 'store/modules/users'

export class WizardPositionsVm {
  constructor(
    private frameworkId: string,
    private initialPositionId: string,
    private selectedSkills: SelectedSkill[]
  ) {}

  // initial positions for this step are based on the seniority level
  // of the initialPosition that was selected on the previous step.
  // * try and get 3 positions, one above and one below the initialPosition
  // * if this is the lowest seniority level, just get 2 positions above
  // * if this is the highest seniority level, just get 2 positions below
  get initialPositions(): string[] {
    const currentSeniorityLevel =
      store.positions.byId(this.initialPositionId)?.seniorityLevel || 1

    const positionsInFramework = store.frameworks
      .byId(this.frameworkId)
      ?.positions.filter(
        (p) =>
          p.seniorityLevel &&
          (p.id == this.initialPositionId ||
            p.seniorityLevel != currentSeniorityLevel)
      )
      .sort((a, b) => {
        if (!a.seniorityLevel || !b.seniorityLevel) return 0
        return a.seniorityLevel - b.seniorityLevel
      })

    if (!positionsInFramework) return []

    let lowerBound = Math.max(0, currentSeniorityLevel - 1)
    let upperBound = Math.min(
      positionsInFramework.length - 1,
      currentSeniorityLevel + 1
    )

    // ensure 3 items are shown if the current position is the lowest or highest
    if (lowerBound == 0)
      upperBound = Math.min(2, positionsInFramework.length - 1)
    if (upperBound == positionsInFramework.length - 1)
      lowerBound = Math.max(0, positionsInFramework.length - 3)

    return positionsInFramework
      .slice(lowerBound, upperBound + 1)
      .map((p) => p.id)
  }

  get positionOptions(): Option[] {
    const framework = store.frameworks.byId(this.frameworkId)

    if (!framework) return []

    return framework.positions.map((position) => ({
      value: position.id,
      label: position.name,
    }))
  }

  activeUsersForPosition(positionId: string): User[] {
    return this.initialPositionId == positionId && store.currentUser
      ? [store.currentUser]
      : []
  }

  seniorityLevelForPosition(positionId: string) {
    return store.positions.byId(positionId)?.seniorityLevel || 1
  }

  nameForPosition(positionId: string) {
    return store.positions.byId(positionId)?.name || ''
  }

  get requirementsForPosition() {
    // note: this is a way to spoof the requirements count for the
    // PositionCard component - we just need an array the same length
    // as the selected position's requirements count ✨
    return Array(this.selectedSkills.length).fill({
      dummy: true,
    })
  }
}
