import React from 'react'

export const SummaryPositionCoachMark = () => {
  // TODO: this needs a design system change before enabling, to allow
  // clicking on a position card to open the position modal
  return null

  return (
    <div className="relative">
      {/* circle svg */}
      <svg
        width="253"
        height="249"
        viewBox="0 0 253 249"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute right-[-650px] top-[-120px] pointer-events-none z-50"
      >
        <path
          d="M106.134 2C70.0659 2 42.2465 24.205 24.3881 54.4839C12.1803 75.1822 3.3594 96.1501 2.23591 120.555C1.45826 137.447 2.34417 154.789 8.85748 170.574C22.3083 203.171 45.286 227.457 77.6616 242.115C91.402 248.336 107.122 247.905 121.605 244.761C139.81 240.808 158.51 235.251 175.962 228.769C194.674 221.819 214.389 214.987 230.078 202.257C245.706 189.575 248.99 167.874 250.605 148.931C252.957 121.358 244.634 87.2675 230.259 63.4417C219.41 45.4603 200.357 31.8576 181.861 22.6809C140.177 2 117.512 19.2432 90.9648 17.15"
          stroke="#2E9E5D"
          strokeWidth="3"
          strokeLinecap="round"
        />
      </svg>

      <div className="absolute right-[-860px] top-[-10px] text-green-700 italic text-sm w-[200px]">
        Try clicking into a position to see what you’ve just built.
      </div>
    </div>
  )
}
