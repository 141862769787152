import React from 'react'
import { store } from 'store/index'
import { trackEvent } from '../../services/event-tracker'
import { successToast } from '../../utils/success-toast'
import { OnboardingFrameworkConfirmation } from './onboarding-framework-confirmation'

export class OnboardingFrameworkSetupHelperVm {
  constructor(
    private teamId?: string | null,
    private setShowTemplateConfirmation?: (
      showTemplateConfirmation: boolean
    ) => void
  ) {}

  readonly disciplines = [
    'Engineering (software)',
    'Engineering (frontend)',
    'Engineering (backend)',
    'Engineering (mobile)',
    'Engineering (department)',
    'Product Management',
    'Design (product)',
    'Design (brand)',
    'Design (UX/UI)',
    'Data (science & analysis)',
    'User Research',
    'Marketing (B2C)',
    'Marketing (B2B)',
    'Customer Success',
    'Sales',
    'People',
    'Other',
  ]

  async onResetTemplate() {
    trackEvent('$track_onboarding_questionnaire_reset_template_clicked', {
      team_id: this.teamId,
    })

    if (!this.teamId) return

    await store.teams.byId(this.teamId)?.destroy()

    window.location.href = '/orgs/new?use_build_wizard=true'
  }

  get toastContent() {
    return (
      <OnboardingFrameworkConfirmation
        onUndoClick={(e) => this.onRestoreClick(e)}
      />
    )
  }

  onRestoreClick(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation()
    e.preventDefault()

    this.onUndoReset()
  }

  async onUndoReset() {
    if (!this.teamId) return

    await store.teams.restore(this.teamId)

    this.setShowTemplateConfirmation?.(true)
  }

  get disciplineOptions() {
    return this.disciplines.map((p) => {
      return { label: p, value: p }
    })
  }
}
