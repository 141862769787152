import { Avatar } from 'src/design-system'
import React from 'react'
import OrgPreviewCard from '../../../images/org-preview-card.svg'
import * as AppFramePreview from 'components/onboarding-build-wizard/app-frame-preview'

type OrgPreviewProps = {
  orgName: string
}

export const OrgPreview = (props: OrgPreviewProps) => {
  const { orgName } = props

  const name = orgName || 'My organisation'

  return (
    <AppFramePreview.Wrapper
      enableTopNav={false}
      teamName={name}
      contentClassName="w-full"
      wrapperClass="w-full mt-[190px] ml-16 relative bg-white min-w-[900px] overflow-hidden"
    >
      <div>
        <img
          src={OrgPreviewCard}
          aria-hidden="true"
          alt=""
          className="object-cover h-44 w-full"
        />
        <div className="flex flex-row w-full">
          <div>
            <div className="ml-24">
              <Avatar
                className="border border-white -top-10"
                size="lg"
                fallbackText={name[0]}
              />
              <div className="mb-8 pb-8 border-0 border-b border-solid border-gray-100 max-w-[300px]">
                <h4 className="text-3xl mb-2 truncate">{name}</h4>
                <svg
                  width={152}
                  height={13}
                  viewBox="0 0 152 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width={60} height="12.25" rx="6.125" fill="#E7E4E4" />
                  <rect
                    x={68}
                    width={84}
                    height="12.25"
                    rx="6.125"
                    fill="#E7E4E4"
                  />
                </svg>
              </div>
              <svg
                width={298}
                height={107}
                viewBox="0 0 298 107"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="0.25" width={47} height={14} rx={7} fill="#E7E4E4" />
                <circle cx={16} cy="50.25" r={16} fill="#E7E4E4" />
                <circle cx={55} cy="50.25" r={16} fill="#E7E4E4" />
                <circle cx={94} cy="50.25" r={16} fill="#E7E4E4" />
                <circle cx={133} cy="50.25" r={16} fill="#E7E4E4" />
                <circle cx={172} cy="50.25" r={16} fill="#E7E4E4" />
              </svg>
            </div>
          </div>
          <div className="flex-shrink-0 mt-12 absolute right-6">
            <svg
              width={320}
              height={492}
              viewBox="0 0 320 492"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.499996"
                width={305}
                height="234.97"
                rx="7.5"
                fill="white"
              />
              <rect
                x={16}
                y={16}
                width="89.4217"
                height="13.1642"
                rx="6.58209"
                fill="#E7E4E4"
              />
              <g clipPath="url(#clip0_1156_33438)">
                <rect
                  x={16}
                  y="45.1642"
                  width={274}
                  height={112}
                  rx={4}
                  fill="#F7A1E2"
                />
                <path
                  d="M80.128 172.603L197.143 195.118L199.46 196.838L282.275 212.383C282.275 212.383 288.631 214.905 293.891 213.707C299.151 212.509 298.692 208.465 300.55 203.989C302.408 199.513 324.13 132.237 324.13 132.237L325.562 126.978L334.829 89.656L337.75 82.6582L349.153 42.4636L351 35.817L349.198 31.8814L344.878 29.4767L287.714 19.0115L282.521 18.0299L277.63 19.4708L276.657 22.8031L264.078 69.6352L260.217 74.2554L253.793 77.1734L232.06 72.9675L200.165 68.2753L191.29 64.3036L185.269 64.7449L180.547 67.807L178.543 73.9222L172.097 97.4643L172.646 99.3286L166.3 111.739L163.749 121.961L160.324 123.699L156.586 123.979L151.64 122.303L94.0723 108.092L88.902 110.1L84.3359 114.504L71.1191 158.634L70 167.127L72.5292 171.558L80.128 172.603Z"
                  fill="#FFCCF2"
                />
              </g>
              <rect
                x="16.5"
                y="175.664"
                width={19}
                height={19}
                rx="9.5"
                fill="#E7E4E4"
                stroke="white"
              />
              <rect
                x="32.5"
                y="175.664"
                width={19}
                height={19}
                rx="9.5"
                fill="#E7E4E4"
                stroke="white"
              />
              <rect
                x="48.5"
                y="175.664"
                width={19}
                height={19}
                rx="9.5"
                fill="#E7E4E4"
              />
              <rect
                x="48.5"
                y="175.664"
                width={19}
                height={19}
                rx="9.5"
                stroke="white"
              />
              <rect
                x={16}
                y="200.125"
                width="49.5259"
                height="20.8834"
                rx="10.4417"
                fill="#E7E4E4"
              />
              <rect
                x="69.5259"
                y="200.125"
                width="60.5316"
                height="20.8834"
                rx="10.4417"
                fill="#E7E4E4"
              />
              <rect
                x="0.5"
                y="0.499996"
                width={305}
                height="234.97"
                rx="7.5"
                stroke="#E7E4E4"
              />
              <rect
                x="0.5"
                y="256.47"
                width={305}
                height="234.97"
                rx="7.5"
                fill="white"
              />
              <rect
                x={16}
                y="271.97"
                width="89.4217"
                height="13.1642"
                rx="6.58209"
                fill="#E7E4E4"
              />
              <g clipPath="url(#clip1_1156_33438)">
                <rect
                  x={16}
                  y="301.134"
                  width={274}
                  height={112}
                  rx={4}
                  fill="#A1C5F7"
                />
                <path
                  d="M261.443 319.596L262.667 317.548L264.153 316.622L266.852 316.72L284.466 323.616L287.875 324.553L315.191 334.488L319.562 336.907C319.562 336.907 329.527 340.175 330.019 340.339C330.51 340.502 338.083 343.803 338.083 343.803L340.596 345.306L341 347.278L340.159 351.059L332.827 366.027L318.174 387.184L303.795 403.33L293.065 410.367L276.129 422.351L271.07 423.996L242.66 435.686L219.19 439.237L194.059 440L186.913 438.366L184.389 439.009L170.774 435.74L146.309 427.657L138.868 423.179L131.69 420.39L104.744 400.356L74.7072 359.938L67.2881 340.448L60.5791 306.893L60.9069 304.18L60 290.758L62.5787 269.874L66.5997 248.173L69.0254 244.752L71.5822 244L74.8602 244.991L98.429 252.748C98.429 252.748 115.081 260.657 115.485 260.799C115.89 260.941 130.061 265.429 130.061 265.429L143.108 270.517L146.539 272.935L147.129 276.204L145.599 284.843L144.179 297.175L146.878 313.419L151.98 322.025L151.685 323.736L154.963 328.714L171.255 345.241L178.139 347.572L194.714 354.774L212.448 355.569L225.713 354.251L244.66 340.97L256.177 330.076L261.443 319.596Z"
                  fill="#CCE1FF"
                />
              </g>
              <rect
                x="16.5"
                y="431.634"
                width={19}
                height={19}
                rx="9.5"
                fill="#E7E4E4"
                stroke="white"
              />
              <rect
                x="32.5"
                y="431.634"
                width={19}
                height={19}
                rx="9.5"
                fill="#E7E4E4"
                stroke="white"
              />
              <rect
                x="48.5"
                y="431.634"
                width={19}
                height={19}
                rx="9.5"
                fill="#E7E4E4"
              />
              <rect
                x="48.5"
                y="431.634"
                width={19}
                height={19}
                rx="9.5"
                stroke="white"
              />
              <rect
                x={16}
                y="456.096"
                width="49.5259"
                height="20.8834"
                rx="10.4417"
                fill="#E7E4E4"
              />
              <rect
                x="69.5259"
                y="456.096"
                width="60.5316"
                height="20.8834"
                rx="10.4417"
                fill="#E7E4E4"
              />
              <rect
                x="0.5"
                y="256.47"
                width={305}
                height="234.97"
                rx="7.5"
                stroke="#E7E4E4"
              />
              <defs>
                <clipPath id="clip0_1156_33438">
                  <rect
                    x={16}
                    y="45.1642"
                    width={274}
                    height={112}
                    rx={4}
                    fill="white"
                  />
                </clipPath>
                <clipPath id="clip1_1156_33438">
                  <rect
                    x={16}
                    y="301.134"
                    width={274}
                    height={112}
                    rx={4}
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    </AppFramePreview.Wrapper>
  )
}
