import { Banner } from 'src/design-system'
import * as React from 'react'

export type StepTitleProps = {
  title: string
  subtitle?: string
  helpText?: string
}

export const StepTitle: React.VFC<StepTitleProps> = (props) => {
  const { title, subtitle, helpText } = props

  return (
    <div className="mb-10">
      {helpText && (
        <Banner variant="muted" className="bg-background text-gray-600 mb-8">
          {helpText}
        </Banner>
      )}
      <h2 className="text-xl leading-7 text-gray-900">{title}</h2>
      {subtitle && <p className="text-gray-600 mb-0 mt-2">{subtitle}</p>}
    </div>
  )
}
