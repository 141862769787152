import React from 'react'

export const InviteCoachMark = () => {
  return (
    <div className="relative">
      {/* this is the circle svg */}
      <svg
        width="146"
        height="89"
        viewBox="0 0 146 89"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute right-[-30px] top-[-40px] pointer-events-none z-50"
      >
        <path
          d="M107.779 9.43288C97.2348 9.03827 86.9968 6.51775 76.4141 6.45973C67.7536 6.41224 59.045 6.25415 50.3869 6.50102C15.0444 7.50873 -22.2643 66.0641 22.5804 82.3164C53.4473 93.5029 89.7417 83.3011 116.303 65.964C126.625 59.2272 137.988 48.051 142.455 36.1912C151.091 13.2611 121.457 5.32465 104.8 2"
          stroke="#2E9E5D"
          strokeWidth="3"
          strokeLinecap="round"
        />
      </svg>
      {/* this is the -> arrow svg */}
      <svg
        width="37"
        height="65"
        viewBox="0 0 37 65"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute right-[-0px] top-[-110px] pointer-events-none z-50"
      >
        <path
          d="M26.1393 2C24.8717 21.0143 16.4764 39.1125 10.3615 56.7778C9.27812 59.9076 7.8543 64.4349 7.13931 58C6.7091 54.1281 3.94401 49.5606 2.36153 46C1.76661 44.6614 2.13931 40.1463 2.13931 41.6111C2.13931 43.1971 1.70873 45.5312 2.36153 47C3.07008 48.5942 6.99047 66.3117 10.9171 62.7778C13.3823 60.5591 14.3826 56.7215 17.1393 54.5556C20.2136 52.1401 22.5548 48.5845 25.3615 45.7778C27.6612 43.4781 30.3446 41.7947 32.6393 39.5C33.1513 38.988 36.3284 37 35.1393 37"
          stroke="#2E9E5D"
          strokeWidth="3"
          strokeLinecap="round"
        />
      </svg>

      <div className="absolute right-[-90px] top-[-140px] text-green-700 italic text-sm w-[200px]">
        Invite your teammates
      </div>
    </div>
  )
}
