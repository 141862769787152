import * as React from 'react'
import { Avatar, ModalButton } from 'src/design-system'
import cn from 'classnames'
import { store } from 'store/index'
import { TabOption, useWizardContext } from 'components/wizard/wizard-context'
import { ArrowSquareOut } from '@phosphor-icons/react'
import { WithAnalytics } from 'components/with-analytics'
import { INVITE_MODAL_ID } from 'components/invite-modal/utils'
import { NavCoachMark } from './nav-coach-mark'
import { InviteCoachMark } from './invite-coach-mark'
import { trackWizardEvent } from '../wizard-tracking-helper'

export type WrapperProps = {
  teamName: string
  enableTabs?: boolean
  enableInvite?: boolean
  enableTopNav?: boolean
  children?: React.ReactNode
  contentClassName?: string
  wrapperClass?: string
  /**
   * Whether or not to use a scaled down version of the app preview
   */
  small?: boolean
}

const SideNavBarSquare = () => {
  return <div className="relative w-3 h-3 bg-gray-100"></div>
}

type NavBarPillProps = {
  size?: 'sm' | 'md' | 'lg'
  className?: string
}

const NavBarPill = (props: NavBarPillProps) => {
  const { size = 'sm', className } = props
  return (
    <div
      className={cn(
        'relative h-3 bg-gray-100 rounded-full',
        size == 'sm' && 'w-9',
        size == 'md' && 'w-16',
        size == 'lg' && 'w-24',
        className
      )}
    />
  )
}

export const Wrapper: React.VFC<WrapperProps> = (props: WrapperProps) => {
  const {
    teamName,
    enableTabs,
    enableInvite,
    enableTopNav = true,
    children,
    contentClassName,
    wrapperClass,
    small,
  } = props

  const { tabOptions, activeTab, setActiveTab } = useWizardContext()

  const setTab = (tab: TabOption) => {
    trackWizardEvent('$track_wizard_summary_nav_change', {
      tab: tab?.value,
    })
    setActiveTab(tab)
  }

  return (
    <div
      className={cn(
        'bg-gray-50 rounded-tl-sm relative shadow',
        small && 'scale-90 origin-top-left',
        wrapperClass
      )}
    >
      <div className="absolute w-[66px] bg-white h-full rounded-tl-sm border-solid border-t-0 border-b-0 border-l-0 border-r-px border-gray-100 p-6 flex flex-col items-center justify-start gap-y-6">
        <Avatar
          size="xs"
          fallbackText={store.currentUser?.initials || ''}
          className=""
        />
        <SideNavBarSquare />
        <SideNavBarSquare />
        <SideNavBarSquare />
      </div>
      {enableTopNav && (
        <div
          className={cn(
            'absolute h-[56px] w-[calc(100%-66px)] ml-[66px] bg-white border-solid border-t-0 border-b-px border-l-0 border-r-0 border-gray-100 flex items-center justify-between px-5'
          )}
        >
          <div
            className={cn(
              'flex items-center',
              enableTabs ? 'gap-x-2' : 'gap-x-6'
            )}
          >
            {teamName ? (
              <span className="bold">{teamName}</span>
            ) : (
              <NavBarPill size="lg" />
            )}
            {enableTabs ? (
              <>
                <NavCoachMark />
                {tabOptions.map((tab, index) => {
                  return (
                    <button
                      type="button"
                      key={index}
                      onClick={() => setTab(tab)}
                      className={cn(
                        'px-2 py-1 rounded hover:bg-gray-50 cursor-pointer',
                        index === 0 && 'ml-3',
                        activeTab?.value == tab.value
                          ? 'text-theme-40 bg-gray-50'
                          : 'text-gray-600'
                      )}
                    >
                      {tab.label}
                    </button>
                  )
                })}
              </>
            ) : (
              <>
                <NavBarPill size="md" />
                <NavBarPill size="md" />
                <NavBarPill size="sm" />
              </>
            )}
          </div>
          {enableInvite && (
            <div className="flex items-center gap-x-2">
              <WithAnalytics
                event="$track_invite_modal_opened"
                params={{
                  action: 'open_modal',
                  source: 'build_wizard',
                  sourceDetail: 'nav_bar_preview',
                }}
              >
                <ModalButton
                  modalId={INVITE_MODAL_ID}
                  modalProps={{ intent: 'build_wizard' }}
                >
                  <ArrowSquareOut /> Invite
                </ModalButton>
              </WithAnalytics>
              <InviteCoachMark />
            </div>
          )}
        </div>
      )}
      <div
        className={cn(enableTopNav && 'ml-[67px] mt-[57px]', contentClassName)}
      >
        {children}
      </div>
    </div>
  )
}
