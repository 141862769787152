import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { store } from 'store/index'
import { useLocalStorage } from '../../utils/use-local-storage'

export type SkillRequirement = {
  id: number
  positionId: string
  skillId: string
  level: number
}

export type SelectedSkill = {
  skillId: string
  categoryId: string
}

export type TabOption = {
  value: string
  label: string
}

type WizardContextType = {
  // step 1
  orgName: string
  setOrgName: (orgName: string) => void

  // step 2
  role: string
  setRole: (role: string) => void
  orgSize: string
  setOrgSize: (orgSize: string) => void

  // step 3
  teamName: string
  setTeamName: (teamName: string) => void
  teamDiscipline: string
  setTeamDiscipline: (teamDiscipline: string) => void
  frameworkId: string

  // step 4
  initialPositionId: string
  setInitialPositionId: (initialPosition: string) => void

  // step 5
  selectedPositions: string[]
  setSelectedPositions: (
    selectedPositions: string[] | ((prev: string[]) => string[])
  ) => void

  // step 6
  selectedSkills: SelectedSkill[]
  setSelectedSkills: (selectedSkills: SelectedSkill[]) => void
  selectedRequirementLevels: SkillRequirement[]
  setSelectedRequirementLevels: (
    selectedRequirementLevels: SkillRequirement[]
  ) => void
  // step 6 - ai skill generation
  aiSkillName: string
  setAiSkillName: (aiSkillName: string) => void
  aiSkillGenerationTaskId: string
  setAiSkillGenerationTaskId: (aiSkillGenerationTaskId: string) => void

  // summary step
  tabOptions: TabOption[]
  setTabOptions: (tabOptions: TabOption[]) => void
  activeTab: TabOption | undefined
  setActiveTab: (activeTab: TabOption) => void
}

const WizardContext = React.createContext<WizardContextType | null>(null)

export const WizardContextProvider = observer(
  ({ children }: { children: React.ReactNode }) => {
    const localStorageKey = `build-wizard-data-${store.currentUser?.id}`
    const withPrefix = (key: string) => `${localStorageKey}-${key}`

    const [orgName, setOrgName] = useLocalStorage(
      withPrefix('org-name'),
      store.currentUser?.org?.name || ''
    )
    const [teamName, setTeamName] = useLocalStorage(withPrefix('team-name'), '')
    const [teamDiscipline, setTeamDiscipline] = useLocalStorage(
      withPrefix('team-discipline'),
      ''
    )
    const [frameworkId, setFrameworkId] = useLocalStorage(
      withPrefix('framework-id'),
      ''
    )
    const [role, setRole] = useLocalStorage(withPrefix('role'), '')
    const [orgSize, setOrgSize] = useLocalStorage(withPrefix('org-size'), '')

    const [initialPositionId, setInitialPositionId] = useLocalStorage(
      withPrefix('initial-position-id'),
      ''
    )
    const [selectedPositions, setSelectedPositions] = useLocalStorage<string[]>(
      withPrefix('selected-positions'),
      []
    )
    const [selectedSkills, setSelectedSkills] = useLocalStorage<
      SelectedSkill[]
    >(withPrefix('selected-skills'), [])
    const [selectedRequirementLevels, setSelectedRequirementLevels] =
      useLocalStorage<SkillRequirement[]>(
        withPrefix('selected-requirement-levels'),
        []
      )
    const [aiSkillGenerationTaskId, setAiSkillGenerationTaskId] =
      useLocalStorage(withPrefix('ai-skill-generation-task-id'), '')

    const [aiSkillName, setAiSkillName] = useLocalStorage(
      withPrefix('ai-skill-name'),
      ''
    )

    const [tabOptions, setTabOptions] = React.useState<TabOption[]>([])
    const [activeTab, setActiveTab] = React.useState<TabOption>()

    React.useEffect(() => {
      async function fetchDisciplines() {
        await store.frameworks.fetchAll({
          filter: { name: teamDiscipline, library: true },
          include: [
            'positions',
            'frameworks_skills',
            'frameworks_skills.skill_variant',
            'frameworks_skills.skill_variant.skill',
            'frameworks_skills.skill_variant.skill_levels',
            'frameworks_skills.category',
          ],
        })
        const framework = store.frameworks.all.find(
          (f) => f.name === teamDiscipline
        )
        setFrameworkId(framework?.id || '')
      }
      if (teamDiscipline) {
        fetchDisciplines()
      }
    }, [teamDiscipline, setFrameworkId])

    return (
      <WizardContext.Provider
        value={{
          // step 1
          orgName,
          setOrgName,
          // step 2
          role,
          setRole,
          orgSize,
          setOrgSize,
          // step 3
          teamName,
          setTeamName,
          teamDiscipline,
          setTeamDiscipline,
          frameworkId,
          // step 4
          initialPositionId,
          setInitialPositionId,
          // step 5
          selectedPositions,
          setSelectedPositions,
          // step 6
          selectedSkills,
          setSelectedSkills,
          selectedRequirementLevels,
          setSelectedRequirementLevels,
          // step 6 - ai skill generation
          aiSkillName,
          setAiSkillName,
          aiSkillGenerationTaskId,
          setAiSkillGenerationTaskId,
          // summary step
          tabOptions,
          setTabOptions,
          activeTab,
          setActiveTab,
        }}
      >
        {children}
      </WizardContext.Provider>
    )
  }
)

export const useWizardContext = () => {
  const context = React.useContext(WizardContext)

  if (!context) {
    throw new Error(
      'useWizardContext must be used within a WizardContextProvider'
    )
  }

  return context
}
