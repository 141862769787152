import React from 'react'

export const YourPositionCoachMark = () => {
  return (
    <div className="relative">
      {/* circle svg */}
      <svg
        width="77"
        height="75"
        viewBox="0 0 77 75"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute -bottom-20 -left-16"
      >
        <path
          d="M32.8762 2C46.8736 2 58.9675 15.7958 66.2946 26.3231C70.4857 32.3448 74.0918 39.565 74.8106 46.9413C75.7933 57.0262 73.1598 68.6519 61.9962 71.7275C53.7567 73.9975 46.6175 73.0317 38.5468 70.3986C28.9356 67.2629 19.0042 63.2849 11.1623 56.7269C4.67908 51.3052 2.05528 44.1452 2.00052 35.7865C1.9571 29.1572 4.63512 22.8509 9.14431 17.9469C11.5112 15.3728 14.5147 12.7232 17.963 11.8057C20.464 11.1403 23.1161 11.0099 25.6921 11.101C28.3791 11.196 31.829 11.5514 34.4502 12.1883C43.9013 14.4847 32.7342 12.1008 40.5043 14.685"
          stroke="#2E9E5D"
          fill="none"
          strokeWidth="3"
          strokeLinecap="round"
          className="position-line"
          style={{
            strokeDasharray: '229',
            strokeDashoffset: '229',
            animation: '0.6s ease-in-out animate-draw-coach-mark forwards',
          }}
        />
      </svg>

      <svg
        width="66"
        height="92"
        viewBox="0 0 66 92"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="-scale-x-100 absolute -bottom-44 -left-32"
      >
        <path
          d="M63.5615 90.4332C59.4081 71.8365 43.6691 54.2926 32.3084 39.7057C26.0241 31.6368 19.9867 23.136 12.6658 15.986C10.5137 13.8842 2.68518 4.44127 3.49229 4.9874C11.8098 10.6154 36.4461 27.7658 28.9888 21.0195C25.9195 18.2428 21.9041 14.8701 18.5029 12.5557C16.5323 11.2147 3.04602 0.548885 2.02403 1.98161C1.50623 2.70751 2.56168 5.40835 2.73643 6.19081C4.30968 13.2349 5.88294 20.279 7.45619 27.3231C8.94321 33.9812 9.16535 43.7468 14.2746 48.6765"
          stroke="#2E9E5D"
          strokeWidth="3"
          strokeLinecap="round"
          style={{
            strokeDasharray: '229',
            strokeDashoffset: '229',
            animation: '0.6s ease-in-out animate-draw-coach-mark forwards',
          }}
        />
      </svg>

      <div className="absolute -bottom-56 -left-56 text-green-700 italic text-sm max-w-[460px]">
        We assigned you to this position - you can change this later!
      </div>
    </div>
  )
}
