import * as React from 'react'

type OnboardingFrameworkConfirmationProps = {
  onUndoClick: (e: React.MouseEvent<HTMLElement>) => void
}

export const OnboardingFrameworkConfirmation: React.VFC<
  OnboardingFrameworkConfirmationProps
> = (props) => {
  const { onUndoClick } = props

  return (
    <>
      {'Template removed.'}
      <button type="button" onClick={onUndoClick}>
        Undo
      </button>
    </>
  )
}
