import { store } from 'store/index'

export class AiSkillGeneratorVm {
  constructor(
    private aiSkillName: string,
    private aiSkillGenerationTaskId: string
  ) {}

  get generationInProgress(): boolean {
    return this.aiSkillGenerationTaskId !== ''
  }

  async beginAIGeneration() {
    const { asyncTasks } = store

    const task = await asyncTasks.create({
      type: 'open_ai',
      step: 'skill-and-levels-generation',
      attributes: {
        skillName: this.aiSkillName || '',
      },
    })

    if (task.success && task?.data?.asyncTasks) {
      const taskId = Object.keys(task.data.asyncTasks)[0]

      return taskId
    }
  }

  async pollForTaskComplete(taskId: string) {
    const { asyncTasks } = store

    await asyncTasks.byId(taskId)?.pollOne()

    const asyncTask = await asyncTasks.byId(taskId)

    return asyncTask
  }
}
