import { Button, Progress } from 'src/design-system'
import * as React from 'react'
import { useWizard } from 'react-use-wizard'

import ProgressionLogo from '../../../images/progression-logo-green.svg'
import { trackWizardEvent } from 'components/onboarding-build-wizard/wizard-tracking-helper'

export type LeftPaneProps = {
  showSkip?: boolean
  disableContinue?: boolean
  children: React.ReactNode
  onClickContinue: () => void
}

export const LeftPane: React.VFC<LeftPaneProps> = (props) => {
  const { showSkip, disableContinue, children, onClickContinue } = props

  const { previousStep, isFirstStep, stepCount, activeStep, isLoading } =
    useWizard()

  const onPreviousClick = () => {
    trackWizardEvent('$track_wizard_go_back', {
      to: activeStep,
    })
    previousStep()
  }

  const onSkipClick = () => {
    trackWizardEvent('$track_wizard_skip', {
      to: activeStep,
    })
    onClickContinue()
  }

  return (
    <main className="flex flex-col p-8 lg:p-16 w-full md:w-2/5 h-screen md:fixed-top">
      <img
        src={ProgressionLogo}
        width={140}
        height={46}
        alt="Progression Logo"
        className="mb-12 md:mb-14"
      />

      <div className="flex flex-col flex-1">
        <div className="flex justify-between mb-7">
          {!isFirstStep && (
            <button
              type="button"
              className="text-green-600"
              onClick={onPreviousClick}
              disabled={isLoading}
            >
              <span aria-hidden>← </span>Back
            </button>
          )}

          {showSkip && (
            <button
              type="button"
              className="text-green-600 ml-auto"
              onClick={onSkipClick}
              disabled={isLoading}
            >
              Skip
            </button>
          )}
        </div>

        {children}
      </div>

      <footer className="flex justify-between items-center gap-x-3 py-6">
        <div className="flex w-full items-center justify-start">
          <Progress
            value={((activeStep + 1) / stepCount) * 100}
            wrapperClassName="w-4/5 pr-4"
            rootClassName="bg-gray-50"
          />
          <span className="text-gray-500 whitespace-nowrap">
            {activeStep + 1} / {stepCount}
          </span>
        </div>

        <Button
          size="lg"
          onClick={onClickContinue}
          disabled={disableContinue || isLoading}
        >
          {isLoading ? 'Loading...' : 'Continue'}
        </Button>
      </footer>
    </main>
  )
}
