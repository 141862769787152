import React from 'react'
import { useWizardContext } from 'components/wizard/wizard-context'
import { Option, SelectInput } from 'components/atoms/select-input'

const sizes = ['1-50', '51-250', '251-1,000', '1,001-10,000', '10,000+']

export const OrgSizeOptions = () => {
  const { orgSize, setOrgSize } = useWizardContext()

  const options = sizes.map((size) => ({
    label: size,
    value: size,
  }))

  const defaultValue = options.find((option) => option.value === orgSize)

  return (
    <div className="w-full">
      <SelectInput
        className="w-full"
        options={options}
        name={'org_size'}
        onChange={(option) =>
          setOrgSize(String((option as Option)?.value) || '')
        }
        defaultValue={defaultValue}
      />
    </div>
  )
}
