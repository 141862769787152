import React from 'react'

export const NavCoachMark = () => {
  return (
    <div className="relative">
      {/* this is the circle svg */}
      <svg
        width="194"
        height="94"
        viewBox="0 0 194 94"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute right-[-206px] top-[-48px] pointer-events-none z-50"
      >
        <path
          d="M151.935 7.46543C138.585 6.5683 125.586 3.46205 112.075 2.79673C97.0168 2.05519 79.6348 1.20815 64.7557 3.42521C41.7997 6.84577 11.0316 14.5027 5.18861 35.6572C1.29526 49.753 -1.32185 66.7247 11.535 78.5733C17.527 84.0954 28.3033 86.9313 36.6979 88.8085C53.9567 92.6679 72.355 92.5008 90.0299 91.0531C118.132 88.7513 144.31 82.1762 167.968 69.6848C175.528 65.693 182.955 61.112 188.009 54.9605C195.405 45.9582 191.951 36.8904 182.331 30.0907C165.897 18.4745 140.662 19.4935 120.871 22.8183"
          stroke="#2E9E5D"
          strokeWidth="3"
          strokeLinecap="round"
        />
      </svg>

      {/* this is the -> arrow svg */}
      <svg
        width="90"
        height="67"
        viewBox="0 0 90 67"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute right-[-60px] top-[-121px] pointer-events-none z-50"
      >
        <path
          d="M1.78418 2C20.1227 6.45265 37.1808 22.4661 51.3992 34.0561C59.2642 40.4671 67.5622 46.6386 74.4956 54.0707C76.5338 56.2555 85.7284 64.2322 85.2039 63.4167C79.7988 55.0134 63.3128 30.115 69.8435 37.6768C72.5314 40.7893 75.7905 44.8567 78.0137 48.2932C79.3018 50.2843 89.592 63.9348 88.1516 64.9328C87.4219 65.4385 84.7678 64.3399 83.9962 64.1526C77.0499 62.466 70.1035 60.7794 63.1572 59.0928C56.5916 57.4986 46.9214 57.1181 42.1423 51.9322"
          stroke="#2E9E5D"
          strokeWidth="3"
          strokeLinecap="round"
        />
      </svg>

      <div className="absolute right-[-80px] top-[-155px] text-green-700 italic text-sm w-[200px]">
        Switch views from here
      </div>
    </div>
  )
}
