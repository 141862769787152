import { useWizardContext } from 'components/wizard/wizard-context'
import React from 'react'
import * as Wizard from 'components/wizard'
import { Banner, Input, InputGroup } from 'src/design-system'
import { useWizard } from 'react-use-wizard'
import * as AppFramePreview from 'components/onboarding-build-wizard/app-frame-preview'
import {
  Option,
  SelectInput,
  SelectInputProps,
} from 'components/atoms/select-input'
import { SingleValue } from 'react-select'
import { YourTeamStepVm } from './your-team-step-vm'
import { errorToast } from 'app/packs/src/utils/error-toast'
import { trackWizardStepCompleted } from '../wizard-tracking-helper'
import { BuildWizardBanner } from '../build-wizard-banner'

export const YourTeamStep = () => {
  const {
    teamName,
    setTeamName,
    teamDiscipline,
    setTeamDiscipline,
    setSelectedPositions,
    setInitialPositionId,
  } = useWizardContext()

  const { nextStep } = useWizard()

  const onClickContinue = () => {
    if (teamName && teamDiscipline) {
      trackWizardStepCompleted({
        step: 'your_team',
        teamName,
        teamDiscipline,
      })
      nextStep()
    } else {
      errorToast('Please enter a team name, and choose a discipline.')
    }
  }

  const onDisciplineChange: SelectInputProps['onChange'] = React.useCallback(
    (e) => {
      const event = e as SingleValue<Option>
      if (event?.value) {
        setTeamName(event?.label || '')
        setTeamDiscipline(event?.value.toString())
        setSelectedPositions([])
        setInitialPositionId('')
      }
    },
    [setTeamDiscipline, setSelectedPositions, setInitialPositionId, setTeamName]
  )

  const vm = React.useMemo(() => {
    return new YourTeamStepVm(teamDiscipline)
  }, [teamDiscipline])

  return (
    <Wizard.Step>
      <Wizard.LeftPane
        onClickContinue={onClickContinue}
        disableContinue={!teamName || !teamDiscipline}
      >
        <Wizard.StepTitle title="Create your first team" />

        <InputGroup.Root>
          <InputGroup.Label>
            <span>
              Select a team <span className="text-gray-600">*</span>
            </span>
            <div className="my-2 p-0 font-normal">
              <SelectInput
                defaultValue={vm.selectedDiscipline}
                onChange={onDisciplineChange}
                placeholder="Select a track"
                options={vm.disciplineOptions}
                name={'team[discipline]'}
                className="mb-2"
              />
            </div>
          </InputGroup.Label>
        </InputGroup.Root>
        <BuildWizardBanner>
          Teams hold your org’s frameworks: one per discipline. We’ve given some
          starter options from our library here.
        </BuildWizardBanner>
      </Wizard.LeftPane>
      <Wizard.RightPane className="flex overflow-hidden">
        <AppFramePreview.Wrapper
          teamName={teamName}
          wrapperClass="mt-[150px] ml-16 w-full"
        />
      </Wizard.RightPane>
    </Wizard.Step>
  )
}
