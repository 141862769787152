import * as React from 'react'
import cn from 'classnames'

export type RightPaneProps = {
  className?: string
  children: React.ReactNode
}

export const RightPane: React.VFC<RightPaneProps> = (props) => {
  const { children, className } = props

  return (
    <aside
      className={cn(
        'hidden md:flex md:w-3/5 bg-gray-50 md:absolute md:top-0 md:right-0 scrollable h-screen',
        className
      )}
    >
      {children}
    </aside>
  )
}
