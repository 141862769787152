import { Button } from 'src/design-system'
import { WithAnalytics } from 'components/with-analytics'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { store } from 'store/index'

import ProgressionLogo from '../../../images/progression-logo-green.svg'
import { useWizardContext } from './wizard-context'

export type FullPageProps = {
  children: React.ReactNode
}

export const FullPage: React.VFC<FullPageProps> = observer((props) => {
  const { children } = props

  const { teamName } = useWizardContext()

  const getStartedFeatureFlagEnabled =
    store.featureFlags.featureEnabled('get_started_modal')
  let keepBuildingLink = '/review-your-framework'
  if (getStartedFeatureFlagEnabled) keepBuildingLink += '?open_get_started=true'

  return (
    <main className="w-full bg-background">
      <div className="flex flex-col px-8 pt-8 md:px-16 md:pt-16 w-full overflow-auto max-w-7xl mx-auto">
        <img
          src={ProgressionLogo}
          width={140}
          height={46}
          alt="Progression Logo"
          className="mb-7 sm:mb-4"
        />

        <div className="flex justify-center flex-col m-auto text-center max-w-[600px]">
          <h1 className="mb-2 text-gray-800 leading-10">
            Amazing job — your {teamName} framework is well underway!
          </h1>
          <p className="text-base mb-6 leading-6">
            Don’t stop now. Continue building out your framework, explore our
            Library of content, and invite your team in to collaborate.
          </p>
          <div className="flex justify-center gap-3">
            <WithAnalytics event="$track_wizard_keep_building">
              <Button size="lg" as="a" href={keepBuildingLink}>
                Go to my framework
              </Button>
            </WithAnalytics>
          </div>
        </div>
      </div>

      <div className="flex flex-col pt-8 md:pt-16 overflow-scroll h-full bg-background">
        <div className="flex justify-center overflow-x-auto pt-36 mx-auto px-24 h-full">
          {children}
        </div>
      </div>
    </main>
  )
})
