import React from 'react'
import * as Wizard from 'components/wizard'

import { OrgNameStep } from './steps/org-name-step'
import { YourTeamStep } from './steps/your-team-step'
import { ChooseSomeSkillsStep } from './steps/choose-some-skills-step'
import { AddPositionStep } from './steps/add-position-step'
import { SummaryStep } from './steps/summary-step'

export const BuildWizardPage = () => {
  return (
    <div className="min-h-screen h-full">
      <Wizard.Root>
        <OrgNameStep />
        <YourTeamStep />
        <AddPositionStep />
        <ChooseSomeSkillsStep />
        <SummaryStep />
      </Wizard.Root>
    </div>
  )
}
