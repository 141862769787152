import { useWizardContext } from 'components/wizard/wizard-context'
import React from 'react'
import * as Wizard from 'components/wizard'
import { Input, InputGroup } from 'src/design-system'
import { OrgPreview } from '../org-preview'
import { useWizard } from 'react-use-wizard'
import { errorToast } from 'app/packs/src/utils/error-toast'
import { store } from 'store/index'
import { trackWizardStepCompleted } from '../wizard-tracking-helper'
import { RoleOptions } from '../role-options'
import { OrgSizeOptions } from '../org-size-options'
import { BuildWizardBanner } from '../build-wizard-banner'

export const OrgNameStep = () => {
  const { orgName, setOrgName, orgSize, role } = useWizardContext()

  const { nextStep } = useWizard()

  const onClickContinue = () => {
    if (orgName) {
      trackWizardStepCompleted({
        step: 'org_name',
        orgNameChanged: store.currentUser?.org?.name !== orgName,
        size: orgSize,
        role,
      })

      nextStep()
    } else {
      errorToast('Please enter a name for your org')
    }
  }

  return (
    <Wizard.Step>
      <Wizard.LeftPane
        onClickContinue={onClickContinue}
        disableContinue={!orgName}
      >
        <Wizard.StepTitle
          title="Your profile"
          helpText="Welcome to Progression! This quick interactive wizard will help you build your first framework and tour our tools. You can change everything afterwards, so don’t worry about making it perfect. Let’s get started."
        />

        <div className="flex flex-col gap-y-6">
          <InputGroup.Root>
            <InputGroup.Label>
              <span>
                Organisation name? <span className="text-gray-600">*</span>
              </span>
            </InputGroup.Label>
            <Input
              name="org-name"
              value={orgName}
              required
              onChange={(e) => setOrgName(e.target.value)}
            />
          </InputGroup.Root>

          <InputGroup.Root>
            <InputGroup.Label>Organisation size</InputGroup.Label>
            <OrgSizeOptions />
          </InputGroup.Root>

          <InputGroup.Root>
            <InputGroup.Label>Your role</InputGroup.Label>
            <RoleOptions />
          </InputGroup.Root>

          <BuildWizardBanner>
            We’ll use this information to personalise your experience.
          </BuildWizardBanner>
        </div>
      </Wizard.LeftPane>
      <Wizard.RightPane className="overflow-hidden">
        <OrgPreview orgName={orgName} />
      </Wizard.RightPane>
    </Wizard.Step>
  )
}
