import {
  FrameworkPage,
  TCategory,
  TDiscipline,
  TRequirement,
} from 'src/design-system'
import { observer } from 'mobx-react-lite'
import cn from 'classnames'
import React from 'react'
import { AiSkillGenerator } from '../ai-skill-generator'
import { errorToast } from 'app/packs/src/utils/error-toast'
import { store } from 'store/index'
import { trackWizardStepCompleted } from '../wizard-tracking-helper'
import { useWizard } from 'react-use-wizard'
import { useWizardContext } from 'components/wizard/wizard-context'
import { WizardFrameworkVm } from './wizard-framework-vm'
import * as AppFramePreview from 'components/onboarding-build-wizard/app-frame-preview'
import * as Wizard from 'components/wizard'
import { BuildWizardBanner } from '../build-wizard-banner'

export const ChooseSomeSkillsStep = observer(() => {
  const { nextStep } = useWizard()

  const [saving, setSaving] = React.useState(false)

  const MIN_SKILLS_TO_SELECT = 3

  const {
    orgName,
    teamName,
    role,
    orgSize,
    teamDiscipline,
    frameworkId,
    initialPositionId,
    selectedPositions,
    selectedSkills,
    setSelectedSkills,
    selectedRequirementLevels,
    setSelectedRequirementLevels,
    aiSkillName,
    setAiSkillName,
    aiSkillGenerationTaskId,
    setAiSkillGenerationTaskId,
  } = useWizardContext()

  const vm = new WizardFrameworkVm(
    orgName,
    teamName,
    role,
    orgSize,
    frameworkId,
    initialPositionId,
    teamDiscipline,
    selectedPositions,
    selectedSkills,
    setSelectedSkills,
    selectedRequirementLevels,
    setSelectedRequirementLevels,
    aiSkillName,
    aiSkillGenerationTaskId
  )

  React.useEffect(() => {
    const fetchTask = async () => {
      await store.asyncTasks.fetchOne(aiSkillGenerationTaskId)
    }
    if (aiSkillGenerationTaskId) {
      fetchTask()
    }
  })

  const onClickContinue = async () => {
    setSaving(true)

    if (selectedSkills.length === 0) {
      errorToast(`Please choose at least ${MIN_SKILLS_TO_SELECT} skills.`)
      setSaving(false)
      return
    }

    const res = await vm.onContinue()
    if (res.success) {
      trackWizardStepCompleted({
        step: 'choose-some-skills',
        skills: vm.frameworkSkills.map((skill) => skill.name),
      })
      store.currentUser?.reload(['org', 'org.onboarding_survey'])
      nextStep()
    } else {
      errorToast(
        'Sorry there was an error saving your skills. Please try again.'
      )
    }
    setSaving(false)
  }

  return (
    <Wizard.Step>
      <Wizard.LeftPane
        onClickContinue={onClickContinue}
        disableContinue={!vm.canContinue || saving}
      >
        <Wizard.StepTitle title="Add skills" />

        <div className="flex flex-row flex-wrap gap-3 mb-10">
          <AiSkillGenerator
            aiSkillGenerationTaskId={aiSkillGenerationTaskId}
            aiSkillName={aiSkillName}
            onGenerationComplete={(id) => {
              vm.onAiSkillReady(id)
            }}
            setAiSkillGenerationTaskId={setAiSkillGenerationTaskId}
            setAiSkillName={setAiSkillName}
          />
          {vm.selectableSkills.map((frameworkSkill) => {
            if (!frameworkSkill.skill) return null
            const selected = selectedSkills.find((selectedSkill) => {
              return selectedSkill.skillId === frameworkSkill.skill?.id
            })?.skillId

            return (
              <div
                key={frameworkSkill.skill.id}
                className={cn(
                  'w-max px-4 py-3 border-px border-gray-100 border-solid rounded cursor-pointer',
                  selected && 'bg-gray-50'
                )}
                onClick={() => vm.onSkillClicked(selected, frameworkSkill)}
              >
                {frameworkSkill.category && (
                  <p className="text-xs m-0">{frameworkSkill.category.name}</p>
                )}
                <p className="font-bold m-0">{frameworkSkill.skill.name}</p>
              </div>
            )
          })}
        </div>

        <BuildWizardBanner>
          Skills sit at the core of Progression, defining position expectations
          and powering growth. Use our library of hundreds, or generate your own
          in seconds.
        </BuildWizardBanner>
      </Wizard.LeftPane>
      <Wizard.RightPane className="flex overflow-auto">
        <AppFramePreview.Wrapper
          teamName={teamName}
          contentClassName="pr-16 pb-16 w-min"
          wrapperClass="mt-[144px] ml-16 w-min h-min min-w-min min-h-full"
          small
        >
          <>
            <FrameworkPage
              disciplines={[vm.frameworkDiscipline as TDiscipline]}
              categories={
                vm.categorisedFrameworkSkills as unknown as TCategory[]
              }
              requirements={
                selectedRequirementLevels as unknown as TRequirement[]
              }
              uncategorisedSkills={[]}
              permissions={{
                allowChangeRequirement: true,
              }}
              eventHandlers={{
                onChangeRequirementLevel: (clickProps) =>
                  vm.handleOnChangeRequirementLevel(clickProps),
              }}
            />
            {selectedSkills.length >= 3 && (
              <div className="relative">
                <div className="absolute left-[300px] -top-16 max-w-[250px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27"
                    height="42"
                    fill="none"
                    className="absolute left-1/3 -top-2"
                  >
                    <path
                      stroke="#2E9E5D"
                      strokeLinecap="round"
                      strokeWidth="3"
                      d="M1.788 39.84C5.965 27.577 14.66 17.172 21.784 6.65c1.262-1.864 2.993-4.586 2.33-.235-.399 2.618.613 6.103 1.026 8.719.155.983-.884 3.882-.626 2.92.279-1.04.972-2.497.802-3.577-.185-1.171.358-13.493-2.842-11.864-2.009 1.023-3.34 3.367-5.531 4.304-2.444 1.045-4.606 2.968-6.943 4.317-1.914 1.105-3.972 1.738-5.883 2.84-.426.247-2.861.993-2.08 1.203"
                    />
                  </svg>
                  <p className="text-green-700 italic text-sm mt-10 -ml-4">
                    This is the required skill level for this position. Hover on
                    the dots to adjust it – you can change this any time
                  </p>
                </div>
              </div>
            )}
          </>
        </AppFramePreview.Wrapper>
      </Wizard.RightPane>
    </Wizard.Step>
  )
})
