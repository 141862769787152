export class YourTeamStepVm {
  constructor(private teamDiscipline: string) {}

  readonly disciplines = [
    { name: 'Engineering (software)', value: 'Large Engineering Team' },
    {
      name: 'Engineering (frontend)',
      value: 'Large Frontend Engineering Team',
    },
    {
      name: 'Engineering (backend)',
      value: 'Backend Engineering Team - Large',
    },
    { name: 'Engineering (mobile)', value: 'Large Mobile Engineering Team' },
    { name: 'Engineering (department)', value: 'Large Engineering Department' },
    { name: 'Product Management', value: 'Large Product Management Team' },
    { name: 'Design (product)', value: 'Large Product Design Team' },
    { name: 'Design (brand)', value: 'Medium Brand Design Team' },
    { name: 'Design (UX/UI)', value: 'Large UX and UI Team' },
    { name: 'Data (science & analysis)', value: 'Large Data Team' },
    { name: 'User Research', value: 'Large Research Team' },
    { name: 'Marketing (B2C)', value: 'B2C Marketing Team - Large' },
    { name: 'Marketing (B2B)', value: 'B2B Marketing Team - Large' },
    { name: 'Customer Success', value: 'Large Customer Success Team' },
    { name: 'Sales', value: 'Large Sales Team' },
    { name: 'People', value: 'Medium People Team' },
  ]

  get disciplineOptions() {
    return this.disciplines.map((discipline) => ({
      value: discipline.value,
      label: discipline.name,
    }))
  }

  get selectedDiscipline() {
    return this.disciplineOptions.find(
      (discipline) => discipline.value === this.teamDiscipline
    )
  }
}
