import { Option } from 'components/atoms/select-input'
import { store } from 'store/index'
import { WizardPositionOption } from './add-position-step'

export class AddPositionStepVm {
  POSITION_SELECT_LIMT = 3

  constructor(private frameworkId: string, private initialPositionId: string) {}

  get positionOptions(): WizardPositionOption[] {
    const framework = store.frameworks.byId(this.frameworkId)

    if (!framework) return []

    return framework.positions
      .sort((a, b) => a.seniorityLevel - b.seniorityLevel)
      .map((position) => ({
        value: position.id,
        label: position.name,
        seniorityLevel: position.seniorityLevel,
      }))
  }

  get selectedPosition() {
    return store.positions.byId(this.initialPositionId)
  }
}
