import { useWizardContext } from 'components/wizard/wizard-context'
import React from 'react'
import * as Wizard from 'components/wizard'
import * as AppFramePreview from 'components/onboarding-build-wizard/app-frame-preview'
import { Option, SelectInput } from 'components/atoms/select-input'
import { InputGroup, PositionCard } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import { AddPositionStepVm } from './add-position-step-vm'
import { useWizard } from 'react-use-wizard'
import { errorToast } from 'app/packs/src/utils/error-toast'
import { YourPositionCoachMark } from './your-position-coach-mark'
import { trackWizardStepCompleted } from '../wizard-tracking-helper'
import { BuildWizardBanner } from '../build-wizard-banner'
import { WizardPositionsVm } from './wizard-positions-vm'
import { MultiValue } from 'react-select'

export type WizardPositionOption = Option & {
  seniorityLevel: number
}

export const AddPositionStep = observer(() => {
  const { nextStep } = useWizard()

  const {
    teamName,
    initialPositionId,
    setInitialPositionId,
    setSelectedPositions,
    selectedPositions,
    teamDiscipline,
    frameworkId,
    selectedSkills,
  } = useWizardContext()

  const vm = new AddPositionStepVm(frameworkId, initialPositionId)

  const wizardPositionsVm = React.useMemo(() => {
    return new WizardPositionsVm(frameworkId, initialPositionId, selectedSkills)
  }, [frameworkId, initialPositionId, selectedSkills])

  const defaultValues =
    selectedPositions.length == 0
      ? undefined
      : selectedPositions
          .map((positionId) => {
            return vm.positionOptions.find((o) => o.value === positionId)
          })
          .filter(Boolean)

  const [selectedOptions, setSelectedOptions] = React.useState<
    WizardPositionOption[]
  >((defaultValues as WizardPositionOption[]) || [])

  const onClickContinue = () => {
    if (initialPositionId) {
      trackWizardStepCompleted({
        step: 'add_position',
        initialPositionId,
        positionName: vm.selectedPosition?.name || '',
      })
      nextStep()
    } else {
      errorToast('Please choose your position.')
    }
  }

  return (
    <Wizard.Step>
      <Wizard.LeftPane
        onClickContinue={onClickContinue}
        disableContinue={selectedPositions.length < vm.POSITION_SELECT_LIMT}
      >
        <Wizard.StepTitle title="Add positions" />

        <InputGroup.Root>
          <InputGroup.Label className="mb-4">
            <span>
              Select 3 positions <span className="text-gray-600">*</span>
            </span>
            <div className="my-2">
              <SelectInput
                name="position"
                multi
                closeMenuOnSelect={false}
                className="font-normal"
                defaultValue={defaultValues as MultiValue<Option>}
                placeholder="Select position"
                value={selectedOptions}
                isClearable={true}
                onChange={(option) => {
                  const values = option as WizardPositionOption[]
                  if (values && values.length <= vm.POSITION_SELECT_LIMT) {
                    setSelectedOptions(values)
                    const sorted = values.sort(
                      (a, b) => a.seniorityLevel - b.seniorityLevel
                    )

                    const mostSenior = sorted[sorted.length - 1]
                    setInitialPositionId(
                      mostSenior && mostSenior.value.toString()
                    )

                    setSelectedPositions(sorted.map((o) => o.value.toString()))
                  }

                  if (!values || values.length === 0) {
                    setInitialPositionId('')
                    setSelectedPositions([])
                  }
                }}
                options={vm.positionOptions}
              />
            </div>
          </InputGroup.Label>
        </InputGroup.Root>

        <BuildWizardBanner>
          Positions sit in a Track to help your team visualise their progression
          path. A team can have multiple Tracks - for example IC and Management.
        </BuildWizardBanner>
      </Wizard.LeftPane>
      <Wizard.RightPane className="flex overflow-hidden">
        <AppFramePreview.Wrapper
          teamName={teamName}
          contentClassName="pl-20 pt-24 w-full"
          wrapperClass="mt-[150px] ml-16 w-full"
        >
          {initialPositionId && (
            <div className="h-3 mb-4 ml-1 font-bold text-base text-gray-600">
              Individual Contributors
            </div>
          )}
          <div className="flex flex-row items-center gap-x-2">
            {selectedPositions &&
              selectedPositions.map((positionId, index) => (
                <>
                  <PositionCard
                    key={index}
                    className="pointer-events-none"
                    parentDiscipline={{ id: 1, name: teamDiscipline }}
                    position={{
                      seniorityLevel:
                        wizardPositionsVm.seniorityLevelForPosition(positionId),
                      name: wizardPositionsVm.nameForPosition(positionId),
                      customLabel: `P${index + 1}`,
                      activeUsers: wizardPositionsVm.activeUsersForPosition(
                        positionId
                      ) as any,
                      requirements: wizardPositionsVm.requirementsForPosition,
                    }}
                    permissions={{ showLabels: true }}
                  />
                  {selectedPositions.length === vm.POSITION_SELECT_LIMT &&
                    index === selectedPositions.length - 1 && (
                      <YourPositionCoachMark />
                    )}
                </>
              ))}
          </div>
        </AppFramePreview.Wrapper>
      </Wizard.RightPane>
    </Wizard.Step>
  )
})
